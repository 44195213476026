import catalogoApi from "../api/catalogoApi";

export default {
  state: {
    catalogo: []
  },

  getters: {
    getCatalogo: (state) => state.catalogo
  },

  mutations: {
    setCatalogo: (state, catalogo) => {
      var cat = [];
      catalogo.forEach(item => {
        if(item.visible) cat.push(item);
      });
      state.catalogo = cat;
    }
  },

  actions: {
    getCatalogoAsync: async ({ commit, state }) => {
      if(state.catalogo.length) return;
      commit("setLoading", true);
      var items = await catalogoApi.getCatalogo();
      commit("setCatalogo", items);
      commit("setLoading", false);
    }
  }
};
