<template>
  <v-app-bar :color="scrolled < 200 ? 'transparent' : '#000'" absolute dark :class="scrolled < 200 ? '' : 'headerAnim animate__animated animate__fadeInDown'">
    <template v-slot: extension>
      <v-tabs class="cntTab">
        <div class="tabs" v-for="(item, index) in menu" :key="index">
          <v-tab class="tab" :to="item.route" @click.native="$scrollToTop" @click="focusTab">{{ item.title }}</v-tab>
        </div>
      </v-tabs>
      <carts-button-vue @click="blurTab"></carts-button-vue>
    </template>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import CartsButtonVue from "./CartsButton.vue";

export default {
  name: "TabView",
  props: ["scrolled"],
  components: {
    CartsButtonVue
  },

  data() {
    return {};
  },

  mounted() {
    if(window.location.href.includes('/carrello') || window.location.href.includes('/pagamento')) {
      this.blurTab();
    }
  },

  computed: {
    ...mapState({
      menu: state => state.menu
    })
  },

  methods: {
    blurTab() {
      this.$el.querySelector(".v-tabs-slider-wrapper").style.display = "none";
    },

    focusTab() {
      this.$el.querySelector(".v-tabs-slider-wrapper").style.display = "block";
    }
  }
};
</script>

<style lang="scss">
@import "../app";
.v-toolbar__content {
  .cntTab .v-slide-group__content {
    justify-content: flex-start;
    .tabs {
      display: flex;
      margin: 0 16px;
      .tab {
        font-weight: bold;
        color: rgba($color: #fff, $alpha: 1.0) !important;
      }
    }
  }
}
</style>
