import Vuex from "vuex";
import Vue from "vue";
import catalogoStore from "./storeSections/catalogo";
import homeStore from "./storeSections/home";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menu: [
      { icon: "mdi-home", title: "Home", route: "/" },
      { icon: "mdi-book-open-variant", title: "Catalogo", route: "/catalogo" },
      { icon: "mdi-calendar-text", title: "Contatti", route: "/contatti" }
    ],
    contatti: [
      { info: "Piazza Sant'Antonio 5, Torgiano - PG", icon: "mdi-home-silo", type: "text" },
      { info: "3478315151", icon: "mdi-phone", type: "phone" },
      { info: "info@artlabitalia.it", icon: "mdi-email", type: "mail" }
    ],
    carrello: [],
    loading: false,
    ...catalogoStore.state,
    ...homeStore.state
  },

  getters: {
    getMenu: state => state.menu,
    getLoading: state => state.loading,
    getCarrello: state => state.carrello,
    ...catalogoStore.getters,
    ...homeStore.getters
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setCarrello(state, carrello) {
      state.carrello = carrello;
    },

    setItemInCarrello(state, item) {
      state.carrello.push(item);
      localStorage.setItem("carts", JSON.stringify(state.carrello));
    },

    deleteItemInCarrello(state, item) {
      var indexItem = state.carrello.findIndex(el => el.id == item.id);
      state.carrello.splice(indexItem, 1);
      localStorage.setItem("carts", JSON.stringify(state.carrello));
    },

    ...catalogoStore.mutations,
    ...homeStore.mutations
  },

  actions: {
    ...catalogoStore.actions,
    ...homeStore.actions
  }
});
