import homeApi from "../api/homeApi";

export default {
  state: {
    home: null
  },

  getters: {
    getHome: state => state.home
  },

  mutations: {
    setHome: (state, homeConfigs) => {
      var home = null;
      homeConfigs.forEach(item => {
        if (item.visible) home = item;
      });
      if(!home) home = homeConfigs[0];
      state.home = home;
    }
  },

  actions: {
    getHomeAsync: async ({ commit, state }) => {
      if (state.home) return;
      commit("setLoading", true);
      var items = await homeApi.getHome();
      commit("setHome", items);
      commit("setLoading", false);
    }
  }
};
