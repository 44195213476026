<template>
  <v-btn icon :to="'/carrello'" @click="carrelloClick">
    <div class="elements" v-if="carrello.length">{{ carrello.length }}</div>
    <v-icon>mdi-shopping</v-icon>
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      carrello: state => state.carrello
    })
  },

  methods: {
    carrelloClick() {
      this.$emit("click", "eventoClick");
    }
  }
};
</script>

<style lang="scss">
.elements {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: 3px;
  z-index: 1;
}
</style>
