import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("./views/Home.vue")
  },
  {
    path: "/catalogo",
    name: "catalogo",
    component: () => import("./views/Catalogo.vue"),
  },
  {
    path: "/catalogo/:id",
    name: "detail",
    props: true,
    component: () => import("./views/CatalogoDetail.vue")
  },
  {
    path: "/contatti",
    name: "contatti",
    component: () => import("./views/Contatti.vue")
  },
  {
    path: "/carrello",
    name: "carrello",
    component: () => import("./views/Carrello.vue")
  },
  {
    path: "/pagamento-success",
    name: "pagamento-success",
    component: () => import("./views/PagamentoSuccess.vue")
  },
  {
    path: "/pagamento-error",
    name: "pagamento-error",
    component: () => import("./views/PagamentoError.vue")
  }
];

export default new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes: routes
});
