import axios from "axios";

export default {
  baseUrl: process.env.VUE_APP_API_BASE + "/home",

  async getHome() {
    try {
      var res = await axios.get(this.baseUrl);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
