import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import VueHead from "vue-head";
import imageViewer from "image-viewer-vue";
import PrimeVue from "primevue/config";
import 'primeicons/primeicons.css'


Vue.use(VueHead);
Vue.use(imageViewer);
Vue.use(PrimeVue)

Vue.config.productionTip = false;

Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);

new Vue({
    vuetify,
    render: h => h(App),
    store,
    router
}).$mount("#app");
