<template>
  <div>
    <v-app-bar :color="scrolled < 200 ? 'transparent' : '#000'" dark absolute :class="scrolled < 200 ? '' : 'headerAnim animate__animated animate__fadeInDown'">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <carts-button-vue></carts-button-vue>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary class="v-navigation-drawer--fixed">
      <v-list nav dense>
        <v-list-item-group active-class="black--text text--accent-4">
          <v-list-item v-for="(item, index) in menu" :key="index" :to="item.route" @click.native="$scrollToTop">
            <v-list-item-icon v-if="showIcons">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CartsButtonVue from './CartsButton.vue';
export default {
  name: "Drawer",

  props: ["scrolled"],

  data: () => ({
    drawer: false,
    showIcons: true
  }),

  computed: {
    ...mapState({
      menu: state => state.menu
    })
  },

  components: {
    CartsButtonVue
  }
};
</script>

<style lang="scss">
.v-toolbar__content {
  justify-content: space-between;
  .v-list-item__title {
    font-size: 1rem !important;
    text-transform: uppercase;
  }
}
</style>
