import axios from "axios";

export default {
  baseUrl: process.env.VUE_APP_API_BASE + "/catalogo",

  async getCatalogo() {
    try {
      var res = await axios.get(this.baseUrl);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async getItemById(id) {
    try {
      var res = await axios.get(this.baseUrl + "/" + id);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async itemInvisible(item) {
    try {
      var res = await axios.put(this.baseUrl + "/itemInvisible", item);
      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
};
