<template>
  <v-app v-scroll="onScroll">
    <template v-if="isPhone">
      <Drawer :scrolled="scrolled" />
    </template>

    <template v-else>
      <TabView :scrolled="scrolled" />
    </template>

    <div class="main" :class="isPhone ? 'phone' : ''" :style="loading ? 'display: flex; justify-content: center' : ''">
      <v-progress-circular style="margin-top: 6rem" v-if="loading" :size="30" :width="4" color="white" indeterminate></v-progress-circular>
      <router-view :isPhone="isPhone"></router-view>
    </div>
  </v-app>
</template>

<script>
import Drawer from "./components/Drawer";
import TabView from "./components/TabView";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "App",

  components: {
    Drawer,
    TabView
  },

  mounted() {
    var carts = localStorage.getItem("carts");
    if (!carts) return;
    else this.setCarrello(JSON.parse(carts));
  },

  data: () => ({
    scrolled: 0
  }),

  computed: {
    ...mapGetters({
      menu: "getMenu",
      loading: "getLoading"
    }),

    isPhone() {
      return this.$vuetify.breakpoint.name == "xs" || this.$vuetify.breakpoint.name == "sm";
    }
  },

  methods: {
    ...mapMutations(["setCarrello"]),
    onScroll(e) {
      this.scrolled = e.target.scrollingElement.scrollTop;
    }
  }
};
</script>

<style lang="scss">
@import "./app";

.main {
  background: linear-gradient(180deg, rgb(0, 1, 7) 0%, rgb(33, 45, 63) 35%, rgb(40, 59, 77) 100%);
  height: 100%;
}
</style>
